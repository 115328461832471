import { createAction, props } from '@ngrx/store';
import { AllowedContainerTypes, SpptNames } from '@rims/lib';

const PREFIX = '[Product Groups]';
export interface OpenAddContainerToProductGroupDialogPayload {
  productGroupId: number;
  allowedContainerTypes: AllowedContainerTypes;
}

export interface CloseAddContainersToProductGroupDialogPayload {
  productGroupId: number;
  containerIds: number[];
}

export interface OpenChangeBudiLifecycleDialogPayload {
  productGroupBudiInfoId: number;
}

export interface SetSystemProcedurePackTypePayload {
  viewId: number;
  budiInfoId: number;
  groupEntityId: number;
  groupId: number;
  expand: string[];
  sppt: SystemProcedurePackType;
}

export interface SystemProcedurePackType {
  notApplicable: boolean;
  id: number;
  name: SpptNames;
}

export const openAddContainerToProductGroupDialog = createAction(
  `${PREFIX} Open Add Container to Product Group Dialog`,
  props<OpenAddContainerToProductGroupDialogPayload>()
);
export const closeAddContainersToProductGroupDialog = createAction(
  `${PREFIX} Close Add Containers to Product Group Dialog`,
  props<CloseAddContainersToProductGroupDialogPayload>()
);

export const openChangeBudiLifecycleDialog = createAction(
  `${PREFIX} Open Change BUDI Lifecycle Dialog Dialog`,
  props<OpenChangeBudiLifecycleDialogPayload>()
);
export const closeChangeBudiLifecycleDialog = createAction(
  `${PREFIX} Close Change BUDI Lifecycle Dialog Dialog`,
  props<OpenChangeBudiLifecycleDialogPayload>()
);

export const reloadProductGroupItemsAndRelatedEntities = createAction(
  `${PREFIX} Reload Product Group Items and Related Entities`
);

export const reloadContainersAndRelatedEntities = createAction(
  `${PREFIX} Reload Product Group Containers and Related Entities`
);

export const setSystemProcedurePackType = createAction(
  `${PREFIX} Set System Procedure Pack Type`,
  props<SetSystemProcedurePackTypePayload>()
);

export const getProductGroupActors = createAction(`${PREFIX} Get Product Group Actors`);

export const deleteProductGroupResponsibilities = createAction(
  `${PREFIX} Delete Product Group Responsibilities`,
  props<{ ids: number[] }>()
);

export const deleteProductGroupResponsibilitiesSuccess = createAction(
  `${PREFIX} Delete Product Group Responsibilities Success`
);
